<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		startScale?: number;
		finishScale?: number;
		duration?: number;
	}>(),
	{ startScale: 1, finishScale: 0.7, duration: 1.2 }
);

const start = computed(() => `scale(${props.startScale})`);
const finish = computed(() => `scale(${props.finishScale})`);
const duration = computed(() => `${props.duration}s`);
</script>

<template>
	<div>
		<slot />
	</div>
</template>

<style scoped lang="scss">
div {
	animation: zoomInOut v-bind(duration) infinite alternate;
}

@keyframes zoomInOut {
	0% {
		transform: v-bind(start);
	}
	100% {
		transform: v-bind(finish);
	}
}
</style>
